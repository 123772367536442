@import 'assets/fonts/fonts';
@import url('https://fonts.googleapis.com/css2?family=Urbanist&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css');

$primary-color: #9d8336;
$primary-lite-color: #f5f2ea;

//breakpoints
$xSmall: 0px;
$small: 600px;
$medium: 900px;
$large: 1200px;
$xLarge: 1536px;

:root {
  --form-builder-primary-color: #{$primary-color};
  --form-builder-primary-color-light: #{$primary-lite-color};
}

body {
  margin: 0;
  font-family: 'Urbanist', sans-serif;
  background: #fff;
}
h1 {
  font-weight: 700;
  margin: inherit;
  font-size: 28px;
}
h2 {
  font-weight: 700;
  margin: inherit;
}
h3 {
  font-weight: 700;
  margin: inherit;
}
h4 {
  font-weight: 700;
  margin: inherit;
  margin-bottom: 6px;
}

.modal-scrollable h4 {
  font-size: 20px;
}

.modal-scrollable {
  position: fixed;
  overflow: auto !important;
  background-color: #fff;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%),
    0px 9px 46px 8px rgb(0 0 0 / 12%);
  max-height: 70vh;
  height: max-content;
  min-width: min(30rem, 90vw);
  max-width: 50vw;
  width: max-content;
  inset: 0px;
  padding: 32px;
  border-radius: 12px;
  margin: auto;
}

.MuiTabs-indicator {
  height: 3px !important;
  background-color: #9d8336 !important;
}

.Mui-selected {
  color: #9d8336 !important;
}
.MuiTabs-flexContainer {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  .MuiTab-root {
    max-width: none;
  }
}
.general-information-title {
  margin: 1rem 0;
  .label-wrapper {
    text-align: right;
    margin-right: 1rem;
  }
  .detail-item {
    margin: 1rem 0;
  }
  .social-media-item {
    word-break: break-all;
  }
}
.form-item {
  margin-bottom: 1.25rem !important;
}

.currency {
  .primary-select-wrapper {
    margin-top: -10px;
    .MuiOutlinedInput-root fieldset {
      border: none !important;
    }
  }
}
.MuiDataGrid-root {
  font-weight: 500 !important;
}

.inline-wrapper {
  display: inline-flex;
  align-items: center;
  .inline-item {
    margin: 5px;
  }
}
.MuiCardHeader-content {
  flex: 1 1 auto;
  width: 100%;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
}

.overlay-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  padding: 20px;
  font-size: 2.5rem;
  text-align: center;
}

.select-item-modal,
.select-prerequisite-item-modal {
  width: 100%;
}
.select-item-modal .MuiDataGrid-cell,
.select-prerequisite-item-modal .MuiDataGrid-cell {
  cursor: pointer;
  p {
    display: inline-block !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .select-item-modal {
    width: 600px !important;
  }

  .select-prerequisite-item-modal {
    width: 800px !important;
  }
}

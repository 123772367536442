.topbar-link-items{
    padding: 0 1rem;
}
.topbar-link-navs{
    text-decoration: none;
    .MuiTypography-body1{
        color: #B2B2B2 !important;
       
    }
 
}
.topbar-link-navs-active{
    .MuiTypography-body1{
        color: white !important;
     
    }
    text-decoration: none;
}
.custom-modal-body {
  box-shadow: 0px 8px 60px #00000005;
  border-radius: 6px;
  padding: 32px;
  color: inherit;
  font-family: inherit;
  text-align: left;
  top: 0px !important;
  left: 0px !important;
  transform: none !important;
  max-height: 100vh;
  overflow-y: auto;
  outline: none;
}

// ----------------- Responsive global styles ----------------------

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .custom-modal-body {
    top: 50% !important;
    left: 50% !important;
    -webkit-transform: translate(-50%, -50%) !important;
    -moz-transform: translate(-50%, -50%) !important;
    -ms-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
    max-height: auto;
    overflow-y: none;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
}
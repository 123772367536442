.color-picker {
  .react-colorful {
    width: 100%;
    height: 8rem;
    background-color: white;
    text-align: center;
  }

  .react-colorful__hue-pointer,
  .react-colorful__alpha-pointer {
    width: 20px;
    height: 20px;
  }

  .text-input-wrapper {
    padding: 1rem;
  }

  .hex-color-picker {
    border: 1px solid #f2f2f2;
    margin-top: 0.5rem;

    .react-colorful__hue,
    .react-colorful__alpha {
      height: 8px;
      border-radius: 5px;
      margin: 0 1rem;
    }

    .react-colorful__saturation {
      margin: 0 0 15px 0;
      border-radius: 5px;
      border-bottom: none;
    }

  }

  .rgb-color-picker {
    .react-colorful {
      height: 3rem;
  
    }
    border: 1px solid #f2f2f2;
    margin-top: 0.5rem;

    .react-colorful__alpha {
      height: 1rem;
      border-radius: 5px;
      margin: 1rem;
    }

    .react-colorful__saturation {
      display: none;
    }

    .react-colorful__hue {
      display: none;
    }

  }

}
.live-class-slider {
    .slider-wrapper{
display: flex;
    }
    .MuiInputBase-root{
        flex: 0.25;
        margin-left: 3rem;
    }
    .MuiSlider-root{
flex:1
    }
}
.primary-multi-select-wrapper{
    justify-content: flex-start;
    align-items: center;
    border: #E5E5E5  solid 0.15rem;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    margin-top: 5px;
     .MuiSelect-icon {
        color:#000
     }
}
@import 'scss'; 
#input-file-upload {
    display: none;
  }

#form-file-upload {
    max-width: 100%;
    text-align: center;
    position: relative;
  }

#label-file-upload {
    height: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    width:100%;
    background-color: $primary-lite-color ;
  }

.drag-active{
  background-color: white !important;

}
#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.file-upload-on-upload{
  height: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    width:100%;
    background-color: #E5E5E5 ;
}
.file-upload-content-media-view{
  height: 20rem;
}

  
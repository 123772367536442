@import 'scss'; 

.tab{
    text-transform: none !important;
    font-weight: 600 !important;
    margin-right: 1rem !important;
    padding-left: 0 !important;
    min-width: max-content !important;
    flex: 1;
    justify-content: left !important;
    font-size:16px;
    line-height: 19px;
    @media screen and (max-width: $large) {
        font-size:0.9rem !important;
    }

}

.tab-wrapper{
    box-shadow: 0px 10px 40px #f2f2f280;
    background: white;
    @media screen and (max-width: $large) {
        padding: 0 1rem;
    }
    
}


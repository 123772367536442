@font-face {
    font-family: Lexend;
    src: url(../fonts/Lexend-Regular.ttf);
    font-weight: normal
}
@font-face {
    font-family: Lexend;
    src: url(../fonts/Lexend-SemiBold.ttf);
    font-weight: 600
}
@font-face {
    font-family: Lexend;
    src: url(../fonts/Lexend-Bold.ttf);
    font-weight: bold
}

@font-face {
    font-family: Lexend;
    src: url(../fonts/Lexend-Medium.ttf);
    font-weight: 500
}

@font-face {
    font-family: Lexend;
    src: url(../fonts/Lexend-Bold.ttf);
    font-weight: bold
}

@font-face {
    font-family: Lexend;
    src: url(../fonts/Lexend-Light.ttf);
    font-weight: 300
}


.ent-tab{
    text-transform: none !important;
    font-weight: 700 !important;
    border: #E5E5E5 !important;
    border-style: solid !important;
    border-width: 0 0 4px 0 !important;
    margin-right: 1rem !important;
    padding-left: 0 !important;
    min-width: 6rem !important;
    flex: 1;
    justify-content: left !important;
    font-size:16px;
    line-height: 19px;

}
.completed{
    color: #D8CDAE !important;
    border: #D8CDAE !important;
    border-style: solid !important;
    border-width: 0 0 4px 0 !important;
}


.MuiTabs-indicator{
    height: 3px !important;
    background-color: #9D8336 !important;
}

.Mui-selected{
    color: #9D8336 !important;
}
.MuiTabs-flexContainer{
    justify-content: space-between;
}
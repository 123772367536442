.hr-overlay {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    hr {
      height: 1px;
      position: absolute;
      width: 100%;
      border: none;
      border-bottom: 1px solid black;
    }
    label {
      background: #fbfbfe;
      z-index: 2;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 2px;
    }
  }
  .primary-text-field {
    .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
      border: none !important;
      background: #f4f5fd 0% 0% no-repeat padding-box !important;
      border-radius: 10px !important;
      font-size: 14px !important;
      padding: 13px 15px 13px 15px !important;
    }
    .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
      border-radius: 10px !important;
      border: 1px solid #d4d8f6 !important;
    }
  }
  .sh-Primary-form-label {
    color: #3b4053;
    font-size: 14px !important;
  }
  .socialmedia-button {
    .css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
      font-size: 14px !important;
      height: 50px;
      margin-top: 30px;
    }
  }
  .SignIn-view {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
  }
  .Heading-view {
    color: blue;
    padding-bottom: 40px;
    padding-top: 20px;
  }
  
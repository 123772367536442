.overview-selector{
    .MuiInputBase-root {
        color: #9D8336 !important;
        font-weight: 600;
    }
    .primary-select-wrapper{
        border: none;
    }
    .primary-select-wrapper  .MuiSelect-icon{
        color: #9D8336 !important;
    }

}

.bar{
    width: 0.375rem;
    height: 3rem;
    margin: 0.5rem;
    border-radius: 5px;
}
.user-bar{
    background-color: #F7C85C;
}
.expert-bar{
    background-color: #BA80C6;
}
.admin-bar{
    background-color: #79D4F1;
}
.overview-table{
    .MuiDataGrid-columnHeadersInner{
        background-color: white;
    }
}

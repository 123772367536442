//Removing the default password hide view eye for MS Edge and IE
::-ms-reveal {
    display: none;
}

//Removing arrow keys in number fields
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.richTextWrapper {
  border-radius: 6px;
  border: 1px solid #CDCDCD;
  background-color: #F3F3F8;
  margin-top: 10px;
}

.richTextToolbar {
  border-width: 0 0 1px 0;
  border-color: #CDCDCD;
  border-radius: 6px 6px 0 0;
}

.richTextToolbar .rdw-option-wrapper {
  border: 0 !important;
}

.richTextToolbar .rdw-option-wrapper:hover {
  box-shadow: none !important;
  background-color: #f1f1f1;
}
.richTextEditor {
  min-height: 250px;
  padding: 0px 20px;
}

.richTextToolbar label.rdw-link-modal-target-option[for="openLinkInNewWindow"] {
  display: none;
}
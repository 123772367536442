.draggable-table {
    width: 100%;
    border-spacing: 0;
    border: 1px solid #B2B2B2 ;
    border-collapse: collapse;
    tr {
        :last-child {
            td {
                border-bottom: 0;
            }
        }
    }
th {
    margin: 0;
    padding: 0.5rem;
    text-align: left;
    color: #B2B2B2;
}

    td {
        margin: 0;
        padding: 0.5rem;
        border-bottom: 1px solid #B2B2B2;
    
        :last-child {
            border-right: 0;
            border-bottom: 0;
        }
    }
  
}

.dragging-row{
    background: rgba(157, 131, 54, 0.3);
}
.table-data{
    &:first-of-type {
        min-width: 20ch;
      }
}
.handle-wrapper {
    height: 1rem;
    vertical-align: bottom;
    display: inline-block;
    margin-right: 0.5rem;
    svg {
      width: 100%;
      height: 100%;
    }
}
.static-table-row{
    box-shadow: rgb(0 0 0 / 10%) 0px 20px 25px -5px,
    rgb(0 0 0 / 30%) 0px 10px 10px -5px;
  outline: #3e1eb3 solid 1px;
}
.static-data{
    background: white;
    &:first-of-type {
      min-width: 20ch;
    };
}